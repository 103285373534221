<template>
	<div :class="{ 'renewed-dropzone--disabled': disabled }" class="renewed-dropzone">
		<strong>Add Attachment</strong>
		<ul class="mb-6">
			<li>Please do not attach password protected files.</li>
			<li>Maximum of 1 file and total 12 MB size can be attached.</li>
		</ul>
		<Dropzone
			v-model="computedValue"
			:type="type"
			:size="size"
			:fileTypes="fileTypes"
			:disabled="disabled"
			ref="dropzone"
		>
			<template #default="{ validationResult }">
				<div class="renewed-dropzone-upload-ico mb-4">
					<v-icon>icon-upload-q</v-icon>
				</div>
				<slot name="dropzoneMessage">
					<div v-if="dropzoneMessage" class="renewed-dropzone-message">{{ dropzoneMessage }}</div>
					<div v-else class="renewed-dropzone-message">
						<strong>Click to upload</strong> or drag and drop
					</div>
				</slot>
				<div v-if="details" class="mt-2"><small>{{ details }}</small></div>
				<div v-if="validationResult !== true" class="text-error mt-2"><small>{{ validationResult }}</small></div>
			</template>
		</Dropzone>
		<div v-if="computedValue" class="renewed-dropzone-file">
			<div class="d-flex">
				<img src="@/assets/images/PDF_file_icon2.svg" height="40" class="mr-4" />
				<div class="flex-grow-1">
					<div class="d-flex gap-4">
						<div class="flex-grow-1">
							<div class="font-medium">{{ computedValue.name }}</div>
							<div class="renewed-dropzone-size">{{ fileSize }}</div>
						</div>
						<v-checkbox v-model="checkboxValue" readonly color="q_green_1" class="mt-0" />
					</div>
					<div class="d-flex align-center gap-4">
						<div class="renewed-dropzone-loading"></div>
						<div class="text-label ml-auto flex-shrink-0">100%</div>
					</div>
				</div>
				<v-btn
					icon
					color="q_new_red"
					class="align-self-start flex-shrink-0"
					@click="deleteFile()"
				>
					<v-icon>icon-trash-q</v-icon>
				</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import Dropzone from '@/components/document_library/Dropzone.vue'

export default {
	name: "QRenewedDropzone",
	props: {
		value: {
			type: [Array, File],
			default: null,
		},
		type: {
			type: String,
			default: 'single',
		},
		dropzoneMessage: {
			type: String,
			default: '',
		},
		details: {
			type: String,
			default: '',
		},
		fileTypes: {
			type: String,
			default: undefined,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		size: {
			type: Number,
			default: undefined,
		},
	},
	data() {
		return {
			checkboxValue: true,
		};
	},
	computed: {
        computedValue: {
            get() { return this.value },
            set(value) { this.$emit('input', value) },
        },
		fileSize() {
			const size = 1024;
			if (this.computedValue.size > size * size / 10) {
				return (this.computedValue.size / (size * size)).toFixed(2) + ' MB';
			}
			if (this.computedValue.size > size / 10) {
				return (this.computedValue.size / size).toFixed(2) + ' KB';
			}
			return this.computedValue.size + ' B';
		},
	},
	components: {
		Dropzone,
	},
	methods: {
		deleteFile() {
			this.computedValue = null;
			const input = this.$refs.dropzone.$el?.getElementsByTagName('input')?.[0];
			if (input) {
				input.value = '';
			}
		},
	},
}
</script>

<style lang="scss" scoped></style>
