<template>
	<QRenewedControl v-bind="$attrs" v-on="$listeners" class="renewed-lead-lookup">
		<template v-slot="{ on, attrs }">
			<QLeadLookup
				v-bind="attrs"
				v-on="on"
				:returnObject="returnObject"
				prependIcon=""
				appendIcon=""
				prependInnerIcon="icon-q-search"
				:agent-code="user.AgentCode"
			/>
		</template>
	</QRenewedControl>
</template>

<script>
import QRenewedControl from './QRenewedControl.vue';
import QLeadLookup from '@/components/utils/QLeadLookup.vue'

export default {
	inheritAttrs: false,
	name: "QRenewedLeadLookup",
	props: {
		returnObject: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		QRenewedControl,
		QLeadLookup,
	},
}
</script>

<style lang="scss" scoped></style>
