<template>
	<v-dialog v-model="modelValue" width="1000px" max-width="90%" persistent>
		<v-card class="renewed-form px-6 py-4" min-height="">
			<v-row wrap>
				<v-col cols="12">
					<div class="d-flex flex-nowrap">
						<h2 class="flex-grow-1">New Lead Details</h2>
					</div>
					<v-divider></v-divider>
				</v-col>
				<v-col v-if="duplicates && !isNotDuplicate" cols="12">
					<div class="detailed-alert" ref="duplicateAlert">
						<header>
							<div class="detailed-alert-badge detailed-alert-badge__warning"><v-icon>icon-alert-triangle-q</v-icon></div>
							<h3>Potential Duplicate Lead Detected!</h3>
						</header>
						<p>You already have one or more leads matching these details in your history. To avoid duplicates, please review the matched leads below.</p>
						<v-radio-group v-model="selectedDuplicate" class="detailed-alert-important">
							<h4 class="font-semibold text-lg mb-4">Matching Lead(s) Details:</h4>
							<template v-for="(duplicate, index) in duplicates">
								<v-divider v-if="index > 0" :key="duplicate.LeadCode" class="my-8" color="#F1F1F1"></v-divider>
								<div class="d-flex align-start gap-4" :key="duplicate.LeadCode">
									<v-radio :value="duplicate" class="mt-2" />
									<div class="grid grid-cols-2 flex-grow-1">
										<div class="d-flex flex-col gap-3">
											<p class="text-sm"><strong class="mr-2">Lead Code:</strong><span>{{ duplicate.LeadCode }}</span></p>
											<p class="text-sm"><strong class="mr-2">Phone:</strong><span>{{ duplicate.Phone }}</span></p>
											<p class="text-sm"><strong class="mr-2">Email:</strong><span>{{ duplicate.Email }}</span></p>
										</div>
										<div class="d-flex flex-col gap-3">
											<p class="text-sm"><strong class="mr-2">Name:</strong><span>{{ duplicate.Name }}</span></p>
											<p class="text-sm"><strong class="mr-2">Cell Phone:</strong><span>{{ duplicate.CellPhone }}</span></p>
											<p class="text-sm"><strong class="mr-2">Address:</strong><span>{{ duplicate.Street }}, {{ duplicate.City }}, {{ duplicate.State }} {{ duplicate.Zip }}</span></p>
										</div>
									</div>
								</div>
							</template>
						</v-radio-group>
						<p>Select the matching lead you want to use or proceed with creating a new lead by selecting "Not a Duplicate".</p>
						<div class="d-flex">
							<v-btn class="renewed-btn" color="q_green_1" outlined @click="notDuplicate()">Not a Duplicate</v-btn>
							<v-spacer></v-spacer>
							<v-btn :disabled="!selectedDuplicate" class="renewed-btn" color="q_green_1" dark depressed @click="useLead()">Use Matching Lead</v-btn>
						</div>
					</div>
				</v-col>

				<v-col cols="12">
					<v-form class="px-6" ref="form">
						<div class="grid grid-cols-2 gap-x-6">
							<QRenewedTextField
								v-model="lead.FirstName"
								label="Applicant's First Name"
								required
								:maxLength="50"
								type="name"
							/>
							<QRenewedTextField
								v-model="lead.LastName"
								label="Applicant's Last Name"
								required
								:maxLength="50"
								type="name"
							/>
						</div>
						<div class="grid grid-cols-3 gap-x-6">
							<QRenewedTextField
								v-model="lead.BirthDate"
								label="Date of Birth (MM/DD/YYYY)"
								required
								type="date"
							/>
							<QRenewedTextField
								v-model="age"
								label="Age"
								readonly
								required
							/>
							<QRenewedRadioGroup
								v-model="lead.Sex"
								label="Gender"
								required
								:items="[{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' }]"
							/>
						</div>
						<div class="grid grid-cols-1">
							<QRenewedTextField
								v-model="lead.Street"
								label="Address"
								required
								:maxLength="100"
							/>
						</div>
						<div class="grid grid-cols-2 gap-x-6">
							<QRenewedTextField
								v-model="lead.City"
								label="City"
								required
								:maxLength="50"
								type="name"
							/>
							<QRenewedTextField
								v-model="lead.County"
								label="County"
								required
								:maxLength="50"
								type="name"
							/>
						</div>
						<div class="grid grid-cols-2 gap-x-6">
							<QRenewedSelect
								v-model="lead.State"
								:items="stateOptions"
								label="State"
								required
							/>
							<QRenewedTextField
								v-model="lead.Zip"
								label="Zip"
								required
								type="zip"
								:maxLength="5"
							/>
						</div>
						<div class="grid grid-cols-5 gap-x-6">
							<QRenewedTextField
								v-model="lead.CellPhone"
								label="Cell Phone"
								:required="!withoutPhone"
								:maxLength="12"
								type="phone"
								class="col-span-3"
							/>
							<v-checkbox
								v-model="withoutPhone"
								color="q_green_1"
								label="Lead/Client does not have a cell phone"
								:messages="withoutPhone ? 'Please input a Home Phone or a Work Phone.' : ''"
								filled
								dense
								class="renewed-checkbox mt-6 align-self-center col-span-2"
							/>
						</div>
						<div :class="withoutPhone ? 'grid-cols-2 gap-x-6' : 'grid-cols-1'" class="grid">
							<QRenewedTextField
								v-if="withoutPhone"
								v-model="lead.Phone"
								label="Phone Number"
								required
								:maxLength="12"
								type="phone"
							/>
							<QRenewedTextField
								v-model="lead.Email"
								label="Email"
								required
								:maxLength="100"
								type="email"
							/>
						</div>
						<div :class="showSpecifyLeadType ? 'grid-cols-3' : 'grid-cols-2'" class="grid gap-x-6">
							<QRenewedTextField
								v-model="lead.LoanAmount"
								label="Loan Amount"
								required
								type="currency"
								:minValue="1"
								:maxLength="20"
							/>
							<QRenewedSelect
								v-model="lead.LeadType"
								:items="leadTypes"
								label="Lead Type"
								required
								@input="lead.LeadTypeOtherDesc = null"
							/>
							<QRenewedTextField
								v-if="showSpecifyLeadType"
								v-model="lead.LeadTypeOtherDesc"
								label="Please specify the Lead Type"
								required
								:maxLength="100"
							/>
						</div>
						<div class="grid grid-cols-2 gap-x-6">
							<QRenewedSelect
								v-model="lead.SourceofReferral"
								:items="['Agent Referral', 'Client Referral', 'Outside Lead', 'External Partner']"
								label="Source"
								required
								@input="lead.LeadSubSource = null"
							/>
							<QRenewedSelect
								v-if="subSourceOptions"
								v-model="lead.LeadSubSource"
								:items="subSourceOptions"
								label="Sub Source"
								required
							/>
						</div>
						<div v-if="errors.length > 0" class="my-4">
							<v-alert dismissible color="#F79009" class="py-3 text-white" @input="errors = []">
								<h4 class="font-semibold text-lg text-white mb-2">Validation errors:</h4>
								<ul class="d-flex flex-col gap-2">
									<li v-for="(error, index) in errors" :key="index" class="text-white">{{ error }}</li>
								</ul>
							</v-alert>
						</div>
						<div>
							<v-progress-linear v-if="saving" class="mb-2" color="q_green_1" indeterminate></v-progress-linear>
							<div>
								<div class="d-flex">
									<v-btn class="renewed-btn" color="q_new_red" outlined @click="close()">Cancel</v-btn>
									<v-spacer></v-spacer>
									<v-btn :disabled="saving || !!duplicates" class="renewed-btn" color="q_green_1" dark depressed @click="save()">Save</v-btn>
								</div>
							</div>
						</div>
					</v-form>
				</v-col>
			</v-row>
		</v-card>
	</v-dialog>
</template>

<script>
import moment from 'moment';
import QRenewedTextField from '@/components/utils/RenewedForm/QRenewedTextField.vue';
import QRenewedRadioGroup from '@/components/utils/RenewedForm/QRenewedRadioGroup.vue';
import QRenewedSelect from '@/components/utils/RenewedForm/QRenewedSelect.vue';
import stateOptions from '@/store/StateOptions';
import QuilityAPI from '@/store/API/QuilityAPI.js';

const leadTypes = ['Debt Free Life', 'Final Expense', 'Infinite Banking', 'Life Insurance', 'Mortgage Protection', 'PCS', 'Recruiting', 'Retirement Solutions', 'Spanish Mortgage Protection', 'Tax-Free Retirement', 'Other'];

export default {
	name: "QNewLeadDetailsFormDialog",
	props: {
		value: {
			type: Boolean,
			required: true,
		},
	},
	data () {
		return {
			lead: {},
			withoutPhone: false,
			saving: false,
			errors: [],
			isNotDuplicate: false,
			duplicates: null,
			selectedDuplicate: null,
			stateOptions,
			leadTypes,
		};
	},
	computed: {
		modelValue: {
			get() {
				return this.value;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		age() {
			const date = moment(this.lead.BirthDate, 'MM/DD/YYYY', true);
			return date.isValid()
				? moment().diff(date, 'years')
				: null;
		},
		showSpecifyLeadType() {
			return this.lead.LeadType === 'Other';
		},
		subSourceOptions() {
			if (this.lead.SourceofReferral === 'Outside Lead') {
				return ['Social Media', 'Other'];
			}
			if (this.lead.SourceofReferral === 'External Partner') {
				return ['Razor Ridge', 'Level Up'];
			}
			return undefined;
		},
	},
	methods: {
		close() {
			this.modelValue = false;
		},
		save() {
			this.errors = [];
			if (!this.$refs.form.validate()) {
				const id = Object.entries(this.$refs.form.errorBag).find((item) => item[1])
				if (id) {
					document.getElementById(`input-${id[0]}`)?.scrollIntoView({ behavior: "smooth" });
				}
				this.errors = ["Please correct invalid fields."];
			}
			if (this.errors.length) {
				return;
			}
			const lead = {
				...this.lead,
				Age: this.age,
				isNotDuplicate: this.isNotDuplicate,	// TODO wait until the API is fixed
				Phone: this.lead.Phone || "",
			};
			this.saving = true;
			QuilityAPI.saveNonReferralLead(this.user.Agent.AgentCode, lead, true)
				.then(async (response) => {
					if (response.success) {
						this.$emit('lead-created', response.data);
						this.close();
						return;
					}
					if (response.duplicates) {
						// TODO wait until the API is fixed
						this.duplicates = response.duplicates;
						this.selectedDuplicate = this.duplicates[0];
						await this.$nextTick();
						this.$refs.duplicateAlert?.scrollIntoView({ behavior: "smooth" });
						return;
					}
					this.errors = Object.values(response.errors).flat();
				})
				.catch((response) => {
					this.errors = Object.values(response?.rawError?.errors || {}).flat();
				})
				.finally(() => {
					this.saving = false;
				});
		},
		notDuplicate() {
			this.isNotDuplicate = true;
			this.duplicates = null;
		},
		useLead() {
			this.$emit('lead-selected', this.selectedDuplicate);
			this.close();
		},
	},
	watch: {
		value(newValue) {
			if (newValue) {
				this.lead = {};
				this.withoutPhone = false;
				this.saving = false;
				this.errors = [];
				this.isNotDuplicate = false;
				this.duplicates = null;
				this.selectedDuplicate = null;
			}
		},
	},
	components: {
		QRenewedTextField,
		QRenewedRadioGroup,
		QRenewedSelect,
	},
}
</script>

<style lang="scss" scoped></style>
