<template>
	<QRenewedControl v-bind="$attrs" v-on="$listeners" class="renewed-text-area">
		<template v-slot="{ on, attrs }">
			<v-textarea
				v-bind="attrs"
				v-on="on"
				:class="{ 'renewed-text-area--resize-none': !resize }"
			/>
		</template>
	</QRenewedControl>
</template>

<script>
import QRenewedControl from './QRenewedControl.vue';

export default {
	inheritAttrs: false,
	name: "QRenewedTextArea",
	props: {
		resize: {
			type: Boolean,
			required: false,
		},
	},
	components: {
		QRenewedControl,
	},
}
</script>

<style lang="scss" scoped></style>
