<template>
	<QRenewedControl v-bind="$attrs" v-on="$listeners" :required="required" class="renewed-radio-group">
		<template v-slot="{ on, attrs }">
			<v-radio-group
				v-bind="attrs"
				v-on="on"
				:label="labelValue"
				dense
				row
			>
				<slot>
					<template v-if="items">
						<v-radio
							v-for="item in items"
							:label="item.label"
							:value="item.value"
							:key="item.value"
						/>
					</template>
				</slot>
			</v-radio-group>
		</template>
	</QRenewedControl>
</template>

<script>
import QRenewedControl from './QRenewedControl.vue';

export default {
	inheritAttrs: false,
	name: "QRenewedRadioGroup",
	props: {
		label: {
			type: String,
			default: '',
		},
		items: {
			type: Array,
			default: null,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		labelValue() {
			return this.required ? `${this.label}*` : this.label;
		},
	},
	components: {
		QRenewedControl,
	},
}
</script>

<style lang="scss" scoped></style>
