<template>
	<QRenewedControl v-bind="$attrs" v-on="$listeners" class="renewed-text-field">
		<template v-slot="{ on, attrs }">
			<v-select
				v-bind="attrs"
				v-on="on"
				:items="items"
				append-icon="icon-q-down"
				:item-value="itemValue"
				:item-text="itemText"
			/>
		</template>
	</QRenewedControl>
</template>

<script>
import QRenewedControl from './QRenewedControl.vue';

export default {
	inheritAttrs: false,
	name: "QRenewedSelect",
	props: {
		items: {
			type: Array,
			required: true,
		},
		itemValue: {
			type: String,
			default: undefined,
		},
		itemText: {
			type: String,
			default: undefined,
		},
	},
	components: {
		QRenewedControl,
	},
}
</script>

<style lang="scss" scoped></style>
