import { render, staticRenderFns } from "./QRenewedDropzone.vue?vue&type=template&id=564a27ac&scoped=true&"
import script from "./QRenewedDropzone.vue?vue&type=script&lang=js&"
export * from "./QRenewedDropzone.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "564a27ac",
  null
  
)

export default component.exports