<template>
	<div class="renewed-control" ref="control">
		<label v-if="label" :for="labelForValue" class="v-label d-flex align-center">
			<span>{{ labelValue }}</span>
			<v-tooltip v-if="tooltip" top>
				<template v-slot:activator="{ on, attrs }">
					<v-icon color="#1570ef" class="tooltip-ico" v-bind="attrs" v-on="on">icon-info-circle-q</v-icon>
				</template>
				{{ tooltip }}
			</v-tooltip>
		</label>
		<slot :attrs="attrsValue" :on="onValue" :hasError="hasError" />
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: "QRenewedControl",
	props: {
		value: {
			type: [Object, String, Number, Boolean],
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		messages: {
			type: String,
			default: '',
		},
		tooltip: {
			type: String,
			default: '',
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'text',
		},
		maxLength: {
			type: Number,
			default: undefined,
		},
		minValue: {
			type: Number,
			default: undefined,
		},
	},
	data() {
		return {
			labelForValue: '',
			validate: false,
		};
	},
	mounted() {
		this.labelForValue = this.$refs.control?.querySelectorAll('[id]')?.[0]?.id;
	},
    computed: {
		attrsValue() {
			return {
				value: this.computedValue,
				required: this.required,
				rules: this.validationRules,
				readonly: this.readonly,
				disabled: this.disabled,
				placeholder: this.placeholder,
				messages: this.messages,
				filled: true,
				outlined: true,
			};
		},
		onValue() {
			return {
				input: (value) => {
					this.computedValue = value;
					this.validate = true;
				},
				change: (value) => {
					this.computedValue = value;
					this.validate = true;
				},
				blur: () => {
					this.validate = true;
				},
			};
		},
        computedValue: {
            get() { return this.value },
            set(value) { this.$emit('input', value) },
        },
		labelValue() {
			return this.required ? `${this.label}*` : this.label;
		},
		validationRules() {
			const rules = []
			if (this.required) {
				rules.push(this.validateRequiredField);
			}
			if (this.type === 'date') {
				rules.push(this.validateDateField);
			}
			if (this.type === 'zip') {
				rules.push(this.form_rules.zip);
			}
			if (this.type === 'phone') {
				rules.push(this.validatePhoneField);
			}
			if (this.type === 'email') {
				rules.push(this.form_rules.email);
			}
			if (this.type === 'name') {
				rules.push(this.form_rules.valid_name_characters);
			}
			if (this.type === 'percent') {
				rules.push(this.validatePercentField);
			}
			if (this.type === 'alphanumeric') {
				rules.push(this.validateAlphanumericField);
			}
			if (this.type === 'currency') {
				rules.push(this.form_rules.currency);
			}
			if (this.maxLength !== undefined) {
				rules.push(this.validateMaxLengthField);
			}
			if (this.minValue !== undefined) {
				rules.push(this.validateMinValueField);
			}
			return rules;
		},
		hasError() {
			return this.validate && this.validationRules.some((rule) => rule(this.computedValue) !== true);
		},
    },
	methods: {
		validateRequiredField(value) {
			return ((value ?? '').length > 0 || typeof value === 'number'  || typeof value === 'boolean') || 'This field is required.';
		},
		validateDateField(value) {
			if (typeof value === 'undefined' || value === null || value === '') {
				return true;
			}
			if (moment(value, 'MM/DD/YYYY', true).isValid()) {
				return true;
			}
			return 'Enter a valid date.';
		},
		validatePhoneField(value) {
			if (typeof value === 'undefined' || value === null || value === '') {
				return true;
			}

			const str = value.toString();
			const matches = str.match(/([-]|[\d])/g) || [];

			if (str.length == matches.length) {
				return true
			}

			return 'Enter a valid phone number.'
		},
		validatePercentField(value) {
			if (typeof value === 'undefined' || value === null || value === '') {
				return true
			}
			value = Number(value);
			if (typeof value === 'number' && value >= 0 && value <= 100) {
				return true;
			}
			return 'Enter a number from 0 to 100.';
		},
		validateMaxLengthField(value) {
			return (value || '').length <= this.maxLength || `Character limit: ${this.maxLength} characters.`;
		},
		validateAlphanumericField(value) {
			if (typeof value === 'undefined' || value === null || value === '') {
				return true;
			}

			const str = value.toString();
			const matches = str.match(/([a-z]|[A-Z]|[\d])/g) || [];

			if (str.length == matches.length) {
				return true
			}

			return 'Enter only alphanumeric characters.'
		},
		validateMinValueField(value) {
			if (typeof value === 'undefined' || value === null || value === '') {
				return true;
			}
			value = typeof value === 'string' ? value.replaceAll(',', '') : value;
			value = Number(value);
			if (typeof value === 'number' && value >= this.minValue) {
				return true;
			}
			return `Minimum value: ${this.minValue}`;
		},
	},
}
</script>

<style lang="scss" scoped></style>
