<template>
	<v-dialog v-model="modelValue" width="1000px" max-width="90%" persistent>
		<v-card class="renewed-form px-6 py-4" min-height="">
			<v-row wrap>
				<v-col cols="12">
					<div class="d-flex flex-nowrap">
						<h2 class="flex-grow-1">Application Details</h2>
						<v-btn
							icon
							class="ml-2 align-self-start flex-shrink-0"
							@click="close()"
						>
							<v-icon>icon-x-close-q</v-icon>
						</v-btn>
					</div>
					<v-divider></v-divider>
				</v-col>
				<v-col v-if="!submitted" cols="12">
					<div class="grid grid-cols-2 gap-x-32">
						<QRenewedLeadLookup
							v-model="searchLead"
							:disabled="hasCarrierWarnings"
							label="Lead Lookup"
							placeholder="Search by Name, Lead Code, or Phone"
							returnObject
						/>
						<v-btn :disabled="hasCarrierWarnings" class="renewed-btn new-lead-btn text-white" color="q_green_1" depressed @click="$emit('open-new-lead-modal')">
							<div class="d-flex align-center gap-2">
								<v-icon>icon-math-plus-q</v-icon>
								<span>Add New Lead with Application</span>
							</div>
						</v-btn>
					</div>
					<v-divider class="mb-4"></v-divider>
				</v-col>
				<v-col v-if="leadCreated || submitted" cols="12">
					<v-alert color="#17B26A" class="text-white py-3">
						<div class="d-flex align-center">
							<span v-if="leadCreated">New Lead created successfully.</span>
							<span v-else>New Application submitted successfully.</span>
							<v-icon small color="white" class="ml-auto">icon-checkmark-q</v-icon>
						</div>
    				</v-alert>
				</v-col>
				<v-col v-if="!submitted" cols="12">
					<h3>Lead Information</h3>
					<div v-if="leadData">
						<strong class="text-lg">{{ leadData.FirstName }} {{ leadData.LastName }} ({{ leadData.LeadCode }})</strong>
						<div>
							<div><strong class="mr-2">Address:</strong> {{ leadData.Street }}, {{ leadData.City }}, {{ leadData.State }} {{ leadData.Zip }}</div>
							<div><strong class="mr-2">Cell Phone:</strong> {{ leadData.CellPhone || leadData.HomePhone || leadData.WorkPhone || 'N/A' }}</div>
							<div><strong class="mr-2">Phone:</strong> {{ leadData.Phone || 'N/A' }}</div>
							<div><strong class="mr-2">Email:</strong> {{ leadData.Email || 'N/A' }}</div>
						</div>
					</div>
					<p v-else>Please choose a lead to attach this application to. If the lead is not in our system, you can create a new lead by clicking the "Add New Lead with Application" button above.</p>
				</v-col>
				<v-col v-if="!submitted && duplicate && !isNotDuplicate" cols="12">
					<div class="detailed-alert detailed-alert__outlined" ref="duplicateAlert">
						<header>
							<div class="detailed-alert-badge detailed-alert-badge__warning"><v-icon>icon-alert-triangle-q</v-icon></div>
							<h3>Potential Duplicate Application Detected!</h3>
						</header>
						<p>An application with the same details already exists in your submission history.</p>
						<div class="detailed-alert-important">
							<h4 class="font-semibold text-lg mb-4">Matching Application Details:</h4>
							<div class="grid grid-cols-2">
								<div class="d-flex flex-col gap-3">
									<p class="text-sm"><strong class="mr-2">Applicant’s First Name:</strong><span>{{ duplicate.FirstName }}</span></p>
									<p class="text-sm"><strong class="mr-2">Carrier:</strong><span>{{ duplicate.CarrierName }}</span></p>
									<p class="text-sm"><strong class="mr-2">APV:</strong><span>{{ duplicate.APV }}</span></p>
								</div>
								<div class="d-flex flex-col gap-3">
									<p class="text-sm"><strong class="mr-2">Applicant’s Last Name:</strong><span>{{ duplicate.LastName }}</span></p>
									<p class="text-sm"><strong class="mr-2">Product:</strong><span>{{ duplicate.ProductName }}</span></p>
								</div>
							</div>
						</div>
						<p>Select “Cancel Submission” to cancel the submission process. Select “Not a Duplicate” to confirm that this not a duplicate and proceed with the submission.</p>
						<div class="d-flex">
							<v-btn class="renewed-btn" color="q_green_1" outlined @click="notDuplicate()">Not a Duplicate</v-btn>
							<v-spacer></v-spacer>
							<v-btn class="renewed-btn" color="#F95951" dark depressed @click="close()">Cancel Submission</v-btn>
						</div>
					</div>
				</v-col>

				<v-col v-if="!submitted" cols="12">
					<v-form ref="form">
						<v-expansion-panels :value="[0, 1]" multiple flat>
							<v-expansion-panel class="renewed-expansion-panel">
								<v-expansion-panel-header expand-icon="icon-q-down">Client Information</v-expansion-panel-header>
								<v-expansion-panel-content>
									<div class="grid grid-cols-2 mt-4">
										<v-checkbox
											v-model="leadAsClient"
											:disabled="hasCarrierWarnings"
											color="q_green_1"
											label="Lead same as client"
											class="renewed-checkbox"
											@change="updateClientInformation()"
										/>
									</div>
									<div class="grid grid-cols-2 gap-x-6">
										<QRenewedTextField
											v-model="application.FirstName"
											:disabled="hasCarrierWarnings"
											label="Applicant's First Name"
											required
											:maxLength="50"
											type="name"
										/>
										<QRenewedTextField
											v-model="application.LastName"
											:disabled="hasCarrierWarnings"
											label="Applicant's Last Name"
											required
											:maxLength="50"
											type="name"
										/>
									</div>
									<div class="grid grid-cols-1">
										<QRenewedTextField
											v-model="application.Address"
											:disabled="hasCarrierWarnings"
											label="Street Address"
											required
											:maxLength="100"
										/>
									</div>
									<div class="grid grid-cols-3 gap-x-6">
										<QRenewedTextField
											v-model="application.City"
											:disabled="hasCarrierWarnings"
											label="City"
											required
											:maxLength="50"
											type="name"
										/>
										<QRenewedSelect
											v-model="application.State"
											:disabled="hasCarrierWarnings"
											:items="stateOptions"
											label="State"
											required
										/>
										<QRenewedTextField
											v-model="application.Zip"
											:disabled="hasCarrierWarnings"
											label="Zip"
											required
											type="zip"
											:maxLength="10"
										/>
									</div>
									<div class="grid grid-cols-2 gap-x-6">
										<QRenewedTextField
											v-model="application.CellPhone"
											:disabled="hasCarrierWarnings"
											label="Cell Phone"
											:required="!withoutPhone"
											:maxLength="10"
											type="phone"
										/>
										<v-checkbox
											v-model="withoutPhone"
											:disabled="hasCarrierWarnings"
											color="q_green_1"
											label="Lead/Client does not have a cell phone"
											:messages="withoutPhone ? 'Please input a Home Phone or a Work Phone.' : ''"
											filled
											dense
											class="renewed-checkbox mt-6 align-self-center"
										/>
									</div>
									<div v-if="withoutPhone" class="grid grid-cols-2 gap-x-6">
										<QRenewedTextField
											v-model="application.HomePhone"
											:disabled="hasCarrierWarnings"
											label="Home Phone"
											:maxLength="10"
											type="phone"
										/>
										<QRenewedTextField
											v-model="application.WorkPhone"
											:disabled="hasCarrierWarnings"
											label="Work Phone"
											:maxLength="10"
											type="phone"
										/>
									</div>
									<div class="grid grid-cols-3 gap-x-6">
										<QRenewedTextField
											v-model="application.Email"
											:disabled="hasCarrierWarnings"
											label="Email"
											required
											:maxLength="100"
											type="email"
											class="col-span-2"
										/>
										<QRenewedTextField
											v-model="application.BirthDate"
											:disabled="hasCarrierWarnings"
											label="Date of Birth (MM/DD/YYYY)"
											required
											type="date"
										/>
									</div>
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel class="renewed-expansion-panel">
								<v-expansion-panel-header expand-icon="icon-q-down">Policy Information</v-expansion-panel-header>
								<v-expansion-panel-content>
									<div class="grid grid-cols-2 gap-x-6 mt-8">
										<QRenewedTextField
											v-model="application.AgentFirstName"
											:disabled="hasCarrierWarnings"
											label="Agent's First Name"
											required
											readonly
										/>
										<QRenewedTextField
											v-model="application.AgentLastName"
											:disabled="hasCarrierWarnings"
											label="Agent's Last Name"
											required
											readonly
										/>
									</div>
									<div class="grid grid-cols-3 gap-x-6 align-end">
										<QRenewedTextField
											v-model="application.ApplicationDate"
											:disabled="hasCarrierWarnings"
											label="Application Date (MM/DD/YYYY)"
											tooltip="Tooltip"
											required
											type="date"
										/>
										<QRenewedTextField
											v-model="application.SubmittedDate"
											:disabled="hasCarrierWarnings"
											label="Submitted Date (MM/DD/YYYY)"
											tooltip="The date when the application was submitted into the system."
											required
											type="date"
										/>
										<QRenewedTextField
											v-model="application.ReceivedDate"
											:disabled="hasCarrierWarnings"
											label="Received Date (MM/DD/YYYY)"
											tooltip="The date when the application status was set to Approved."
											type="date"
											readonly
										/>
									</div>
									<div class="grid grid-cols-2 gap-x-6">
										<QRenewedAutocompleteCarriers
											v-model="application.Carrier"
											:disabled="hasCarrierWarnings"
											label="Carrier"
											required
											@update-carrier="carrier = $event"
											@input="updateCarrier()"
										/>
										<QRenewedSelect
											v-model="application.PolicyFrom"
											:disabled="hasCarrierWarnings"
											:items="['Resets', 'Policy Review', 'Neither']"
											label="Policy From"
											required
											@input="updateSplitTransaction()"
										/>
									</div>
									<div v-if="hasCarrierWarnings" ref="carrierWarnings" class="detailed-alert gap-2 mb-4 pb-0">
										<div class="d-flex gap-4">
											<div>
												<div class="detailed-alert-badge detailed-alert-badge__error">
													<v-icon>icon-alert-triangle-q</v-icon>
												</div>
											</div>
											<div v-if="carrierWarnings.contractedWithCarrier">
												<h3 class="text-base text-error mb-1">Carrier ID Missing/Expired!</h3>
												<p class="mb-3">Your writing number/Carrier ID for this carrier is either missing, inactive, or expired. Please note that the writing Number/Carrier ID is provided BY {{ application.CarrierName }} and different for each carrier that you are contracted with and is NOT your State Insurance License.</p>
												<p>If {{ application.CarrierName }} has not provided you with an ID as of yet, you can continue filling out this form without it by clicking “Acknowledge”. Symmetry Financial Group will inquire with the carrier about your contracting status. If you have your Carrier ID, please enter it into HQ at your earliest convenience.
													You must acknowledge this alert before you can proceed with the submission.</p>
											</div>
											<div v-else>
												<h3 class="text-base text-error mb-1">First Time Submission Alert!</h3>
												<p>It appears this is the first time you are writing with this carrier. Unless you have already submitted, we recommend that you do not submit this application directly to the carrier and review it with your Agency Owner to verify the application before submission.<br>You must acknowledge this alert before you can proceed with the submission.</p>
											</div>
										</div>
										<div class="d-flex justify-end">
											<v-checkbox
												v-model="acknowledge"
												color="q_green_1"
												label="Acknowledge*"
												class="renewed-checkbox"
											/>
										</div>
									</div>
									<div class="grid grid-cols-3 gap-x-6">
										<QRenewedSelect
											v-model="application.Product"
											:items="products"
											:disabled="hasCarrierWarnings"
											label="Product"
											required
											item-value="ID" 
											item-text="Product"
											@input="updateProductType()"
										/>
										<QRenewedTextField
											v-model="application.ProductType"
											:disabled="hasCarrierWarnings"
											label="Product Type"
											readonly
											required
										/>
										<QRenewedTextField
											v-model="application.ProductSubtype"
											:disabled="hasCarrierWarnings"
											label="Product Sub-Type"
											readonly
										/>
									</div>
									<div class="grid grid-cols-4 gap-x-6">
										<QRenewedTextField
											v-model="application.PolicyNumber"
											:disabled="hasCarrierWarnings"
											label="Policy Number"
											class="col-span-2"
											:maxLength="20"
											type="alphanumeric"
										/>
										<QRenewedRadioGroup
											v-model="application.SimplifiedUW"
											:disabled="hasCarrierWarnings"
											label="Simplified Underwriting"
											:items="[{ label: 'Yes', value: true }, { label: 'No', value: false }]"
										/>
									</div>
									<div class="grid grid-cols-3 gap-x-6">
										<QRenewedTextField
											v-model="application.FaceAmount"
											:disabled="hasCarrierWarnings"
											label="Face Amount"
											required
											type="currency"
											:minValue="1"
											:maxLength="20"
										/>
										<QRenewedSelect
											v-model="application.ModalType"
											:disabled="hasCarrierWarnings"
											:items="['EFT', 'CC', 'Direct Bill', 'Check', 'Money Order']"
											label="Modal Type"
											required
										/>
										<QRenewedTextField
											v-model="application.ModalPremium"
											:disabled="hasCarrierWarnings"
											label="Modal Premium"
											required
											type="currency"
											:minValue="1"
											:maxLength="20"
										/>
									</div>
									<div class="grid grid-cols-4 gap-x-6">
										<QRenewedSelect
											v-model="application.PaymentMode"
											:items="paymentModeOptions"
											:disabled="hasCarrierWarnings"
											label="Payment Mode"
											required
											item-value="value" 
											item-text="title"
										/>
										<QRenewedTextField
											v-model="APV"
											:disabled="hasCarrierWarnings"
											label="Annualized Premium"
											readonly
											required
										/>
										<QRenewedTextField
											v-model="application.InitialPayment"
											:disabled="hasCarrierWarnings"
											label="Initial Payment"
											type="currency"
											:minValue="1"
											:maxLength="20"
										/>
										<QRenewedRadioGroup
											v-model="application.ReturnOfPremium"
											:disabled="hasCarrierWarnings"
											label="Return of Premium"
											:items="[{ label: 'Yes', value: true }, { label: 'No', value: false }]"
										/>
									</div>
									<div class="grid grid-cols-1">
										<QRenewedTextArea
											v-model="application.PolicyNotes"
											:disabled="hasCarrierWarnings"
											label="Policy Notes"
											messages="Character Limit: 500 Characters"
											:maxLength="500"
											class="mb-4"
										/>
									</div>
									<div class="grid grid-cols-5 gap-x-6">
										<QRenewedRadioGroup
											v-model="application.FirstTimeCarrier"
											:disabled="hasCarrierWarnings"
											label="First time carrier?"
											required
											:items="[{ label: 'Yes', value: true }, { label: 'No', value: false }]"
										/>
										<QRenewedSelect
											v-model="application.SaleType"
											:items="['In Person', 'Telephone Sale', 'Video Sale']"
											:disabled="hasCarrierWarnings"
											label="Sale Type"
											required
											class="col-span-2"
										/>
										<QRenewedRadioGroup
											v-model="application.eApp"
											:disabled="hasCarrierWarnings"
											label="How was this application taken?"
											required
											class="col-span-2"
										>
											<v-radio label="eApp" value="E-App"></v-radio>
											<v-radio label="Paper App" value="Paper App"></v-radio>
										</QRenewedRadioGroup>
									</div>
									<div class="grid grid-cols-5 gap-x-6">
										<QRenewedRadioGroup
											v-model="application.SplitTransaction"
											:items="[{ label: 'Yes', value: true }, { label: 'No', value: false }]"
											:disabled="hasCarrierWarnings"
											label="Split Transaction"
											required
											@change="updatePercentage()"
										/>
										<QRenewedLeadLookup
											v-if="showSplitDetails"
											v-model="application.Agent2AgentID"
											:disabled="hasCarrierWarnings"
											required
											label="Split Agent Lookup*"
											placeholder="Start typing to search for an agent"
											class="col-span-3"
											@input="updateSplitTransaction()"
										/>
										<QRenewedTextField
											v-if="showSplitDetails"
											v-model="application.Percentage"
											:disabled="hasCarrierWarnings"
											label="Split Percentage"
											required
											type="percent"
											@input="updateSplitTransaction()"
										>
											<template #append>
												<strong class="text-2xl text-label">%</strong>
											</template>
										</QRenewedTextField>
									</div>
									<div v-if="showSplitDetails" class="grid grid-cols-1">
										<QRenewedTextArea
											v-model="application.SplitNote"
											:disabled="hasCarrierWarnings"
											label="Split Notes"
											messages="Character Limit: 500 Characters"
											:maxLength="500"
											class="mb-4"
										/>
									</div>
									<div class="grid grid-cols-1 mt-2">
										<QRenewedDropzone
											v-model="file"
											:disabled="hasCarrierWarnings"
											:size="12 * 1024 * 1024"
											type="single"
											details="PDF only"
											fileTypes="pdf/*"
										/>
									</div>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</v-form>
				</v-col>
				<v-col v-if="errors.length > 0" cols="12">
					<v-alert dismissible color="#F79009" class="py-3 text-white" @input="errors = []">
						<h4 class="font-semibold text-lg text-white mb-2">Validation errors:</h4>
						<ul class="d-flex flex-col gap-2">
							<li v-for="(error, index) in errors" :key="index" class="text-white">{{ error }}</li>
						</ul>
    				</v-alert>
				</v-col>

				<v-col cols="12">
					<v-progress-linear v-if="loading" class="mb-2" color="q_green_1" indeterminate></v-progress-linear>
					<div>
						<div v-if="submitted" class="d-flex">
							<v-btn class="renewed-btn ml-auto" color="q_green_1" dark depressed @click="close()">Close</v-btn>
						</div>
						<div v-else class="d-flex">
							<v-btn class="renewed-btn" color="q_new_red" outlined @click="close()">Cancel</v-btn>
							<v-spacer></v-spacer>
							<v-btn :disabled="loading || !!duplicate || hasCarrierWarnings" class="renewed-btn" color="q_green_1" dark depressed @click="submit()">Submit</v-btn>
						</div>
					</div>
				</v-col>
			</v-row>
		</v-card>
	</v-dialog>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js';
import QRenewedSelect from '@/components/utils/RenewedForm/QRenewedSelect.vue';
import QRenewedTextArea from '@/components/utils/RenewedForm/QRenewedTextArea.vue';
import QRenewedTextField from '@/components/utils/RenewedForm/QRenewedTextField.vue';
import QRenewedDropzone from '@/components/utils/RenewedForm/QRenewedDropzone.vue';
import QRenewedLeadLookup from '@/components/utils/RenewedForm/QRenewedLeadLookup.vue';
import QRenewedAutocompleteCarriers from '@/components/utils/RenewedForm/QRenewedAutocompleteCarriers.vue';
import QRenewedRadioGroup from '@/components/utils/RenewedForm/QRenewedRadioGroup.vue';
import stateOptions from '@/store/StateOptions';

const simplifiedUWDefaultTrue = [
	'American Amicable', 'American General', 'American National (ANICO)', 'Americo', 'Assurity',
	'Banner', 'Foresters', 'Gerber Life', 'John Hancock', 'Lafayette Life', 'LGA', 'Lincoln Financial',
	'Manhattan Life', 'Mutual of Omaha', 'Mutual Trust Life', 'NLG', 'North American', 'Occidental',
	'Phoenix Life', 'Principal', 'Prosperity Life', 'Protective', 'Royal Neighbors', 'SBLI', 'TransAmerica',
	'United Home Life',
];
const paymentModeOptions = [{title: 'Monthly', value: 'M'},{title: 'Quarterly', value: 'Q'},{title: 'Semi Annual', value: 'S'},{title: 'Annual', value: 'A'}]

export default {
    name: "QApplicationDetailsFormDialog",
	props: {
		value: {
			type: Boolean,
			required: true,
		},
		newLeadModalOpened: {
			type: Boolean,
			default: false,
		},
		applicationData: {
			type: Object,
			default: null,
		},
		lead: {
			type: Object,
			default: null,
		},
		leadCreated: {
			type: Boolean,
			default: false,
		},
	},
	data () {
		return {
			searchLead: null,
			searchAgent: null,
			application: {},
			leadAsClient: false,
			withoutPhone: false,
			stateOptions,
			file: null,
			carrier: null,
			paymentModeOptions,
			submitted: false,
			isNotDuplicate: false,
			duplicate: null,
			loading: false,
			errors: [],
			carrierWarnings: null,
			acknowledge: false,
		};
	},
    computed: {
		modelValue: {
			get() {
				return this.value && !this.newLeadModalOpened;
			},
			set(value) {
				this.$emit('input', value);
			},
		},
		showSplitDetails() {
			return this.application.SplitTransaction || this.application.PolicyFrom === 'Reset' || this.application.PolicyFrom === 'Policy Review'
		},
		products() {
			return this.carrier?.products || [];
		},
		selectedProduct() {
			return this.products.find((product) => product.ID === this.application.Product)
		},
		APV() {
			if (this.application.PaymentMode === 'A') {
				return (this.application.ModalPremium || 0);
			}
			if (this.application.PaymentMode === 'S') {
				return (this.application.ModalPremium || 0) * 2;
			}
			if (this.application.PaymentMode === 'M') {
				return (this.application.ModalPremium || 0) * 12;
			}
			if (this.application.PaymentMode === 'Q') {
				return (this.application.ModalPremium || 0) * 4;
			}
			return null;
		},
		leadData() {
			return this.searchLead || this.lead;
		},
		hasCarrierWarnings() {
			return this.carrierWarnings?.isFirstTime || this.carrierWarnings?.contractedWithCarrier;
		},
    },
	methods: {
		close() {
			this.modelValue = false;
			this.saving = false;
		},
		submit() {
			this.errors = [];
			if (!this.$refs.form.validate()) {
				const id = Object.entries(this.$refs.form.errorBag).find((item) => item[1])
				if (id) {
					document.getElementById(`input-${id[0]}`)?.scrollIntoView({ behavior: "smooth" });
				}
				this.errors = ["Please correct invalid fields."];
			}
			if (!this.file) {
				this.errors.push("The Attachment is required.");
			}
			if (!this.leadData.LeadCode) {
				this.errors.push("Please choose a lead or create ane one.");
			}
			if (this.errors.length) {
				return;
			}
			// TODO wait backend task
			const application = {
				...this.application,
				isNotDuplicate: this.isNotDuplicate,
				APV: this.APV,
				PrimaryPhone: this.application.CellPhone || this.application.HomePhone || this.application.WorkPhone,
				ApplicationPDFURL: this.file,
				LeadCode: this.leadData.LeadCode,
			};
			this.loading = true;
			QuilityAPI.updateApplication(this.user.Agent.AgentCode, application)
				.then(async (response) => {
					if (response.success) {
						this.submitted = true;
						return;
					}
					if (response.duplicate) {
						this.duplicate = response.duplicate;
						await this.$nextTick();
						this.$refs.duplicateAlert?.scrollIntoView({ behavior: "smooth" });
						return;
					}
					this.errors = Object.values(response.errors).flat();
				})
				.finally(() => {
					this.loading = false;
				});
		},
		checkCarrier() {
			this.carrierWarnings = null;
			this.acknowledge = false;

			QuilityAPI.checkCarrier(this.user.Agent.AgentCode, this.application.Carrier)
				.then(async (response) => {
					this.carrierWarnings = response;
					if (this.hasCarrierWarnings) {
						await this.$nextTick();
						this.$refs.carrierWarnings?.scrollIntoView({ behavior: "smooth" });
					}
				});
		},
		updateCarrier() {
			if (this.application.Product !== null) {
				this.application.Product = null;
				this.updateProductType();
			}
			this.application.SimplifiedUW = simplifiedUWDefaultTrue.includes(this.carrier?.CarrierName);

			this.checkCarrier();
		},
		updateProductType() {
			const productType = this.selectedProduct?.ProductType || null;
			if (this.application.ProductType !== productType) {
				this.application.ProductType = productType;
			}
			const productSubtype = this.selectedProduct?.ProductSubtype || null;
			if (this.application.ProductSubtype !== productSubtype) {
				this.application.ProductSubtype = productSubtype;
			}
		},
		updateSplitTransaction() {
			if (this.application.PolicyFrom === 'Reset' || this.application.PolicyFrom === 'Policy Review' || this.application.Agent2AgentID || this.application.Percentage) {
				this.application.SplitTransaction = true;
			} else if (!this.application.SplitTransaction) {
				this.updatePercentage()
			}
		},
		updatePercentage() {
			if (!this.application.SplitTransaction && this.application.PolicyFrom !== 'Reset' && this.application.PolicyFrom !== 'Policy Review') {
				this.application.Agent2AgentID = null;
				this.application.Percentage = null;
			}
		},
		updateClientInformation() {
			if (this.leadAsClient && this.leadData) {
				const { FirstName, LastName, Address, City, State, Zip, CellPhone, HomePhone, WorkPhone, Email, BirthDate } = this.application;
				this.application = {
					...this.application,
					FirstName: this.leadData.FirstName || FirstName,
					LastName: this.leadData.LastName || LastName,
					Address: this.leadData.Street || Address,
					City: this.leadData.City || City,
					State: this.leadData.State || State,
					Zip: this.leadData.Zip || Zip,
					CellPhone: this.leadData.CellPhone || CellPhone,
					HomePhone: this.leadData.HomePhone || HomePhone,
					WorkPhone: this.leadData.WorkPhone || WorkPhone,
					Email: this.leadData.Email || Email,
					BirthDate: this.leadData.BirthDate || BirthDate,
				};
			}
		},
		notDuplicate() {
			this.isNotDuplicate = true;
			this.duplicate = null;
		},
	},
	watch: {
		value(newValue) {
			if (newValue) {
				this.searchLead = null;
				this.searchAgent = null;
				this.leadAsClient = false;
				this.withoutPhone = false;
				this.file = null;
				this.carrier = null;
				this.submitted = false;
				this.duplicate = null;
				this.isNotDuplicate = false;
				this.loading = false;
				this.errors = [];
				this.carrierWarnings = null;
				this.acknowledge = false;

				this.application = {
					...this.applicationData,
					AgentFirstName: this.applicationData?.AgentFirstName || this.user.Agent?.FirstName || '',
					AgentLastName: this.applicationData?.AgentLastName || this.user.Agent?.LastName || '',
					AgentID: this.applicationData?.AgentID || this.user.Agent?.AgentID || '',
					Product: this.applicationData?.Product || null,
					ProductType: this.applicationData?.ProductType || null,
					ProductSubtype: this.applicationData?.ProductSubtype || null,
					Carrier: this.applicationData?.Carrier || null,
					SimplifiedUW: this.applicationData?.SimplifiedUW || null,
					Agent2AgentID: this.applicationData?.Agent2AgentID || null,
				};
			}
		},
		lead(newValue) {
			if (newValue) {
				this.searchLead = null;
			}
		},
		leadData() {
			this.updateClientInformation();
		},
		acknowledge(newValue) {
			if (newValue) {
				setTimeout(() => {
					this.carrierWarnings = null;
				}, 500);
			}
		},
	},
    components: {
		QRenewedTextArea,
		QRenewedTextField,
		QRenewedSelect,
		QRenewedDropzone,
        QRenewedLeadLookup,
		QRenewedAutocompleteCarriers,
		QRenewedRadioGroup,
    },
}
</script>

<style lang="scss" scoped>
.new-lead-btn {
	margin-top: 1.875rem;
}
</style>
