<template>
	<QRenewedControl v-bind="$attrs" v-on="$listeners" class="renewed-autocomplete-carriers">
		<template v-slot="{ on, attrs }">
			<QAutocompleteCarriers
				v-bind="attrs"
				v-on="on"
				:value="computedValue"
				append-icon="icon-q-down"
				label=""
				:clearable="false"
				@update="updateValue"
			/>
		</template>
	</QRenewedControl>
</template>

<script>
import QRenewedControl from './QRenewedControl.vue';
import QAutocompleteCarriers from '@/components/utils/QAutocompleteCarriers.vue'

export default {
	inheritAttrs: false,
	name: "QRenewedAutocompleteCarriers",
	props: {
		value: {
			type: [Object, String, Number],
			default: null,
		},
	},
	computed: {
        computedValue: {
            get() { return this.value },
            set(value) { this.$emit('input', value) },
        },
	},
	methods: {
		updateValue(value, fullCarrier) {
			this.$emit('update-carrier', fullCarrier);
			if (this.computedValue !== value) {
				this.computedValue = value;
			}
		},
	},
	components: {
		QRenewedControl,
		QAutocompleteCarriers,
	},
}
</script>

<style lang="scss" scoped></style>
