<template>
	<QRenewedControl v-bind="$attrs" v-on="$listeners" class="renewed-text-field">
		<template v-slot="{ on, attrs, hasError }">
			<v-text-field
				v-bind="attrs"
				v-on="on"
				:append-icon="hasError ? 'icon-q-alert-octagon' : undefined"
			>
				<template #append>
					<slot v-if="$slots.append" name="append" />
				</template>
			</v-text-field>
		</template>
	</QRenewedControl>
</template>

<script>
import QRenewedControl from './QRenewedControl.vue';

export default {
	inheritAttrs: false,
	name: "QRenewedTextField",
	components: {
		QRenewedControl,
	},
}
</script>

<style lang="scss" scoped></style>
